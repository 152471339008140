<template>
  <div class="container">
    <div class="contant">
      <h1>店铺基本信息</h1>
      <el-form :model="editMemberForm" ref="editForm" label-width="350px">
        <!--  :rules="editMemberRules" -->
        <template>
          <el-form-item label="店铺名称：" prop="shop_name">
            <!-- <el-input v-model="editMemberForm.shop_name" :maxlength="20"></el-input> -->
            <span>{{editMemberForm.shop_name}}</span>
          </el-form-item>
          <el-form-item label="所选活动分类：" prop="cat_first_name">
            <div>
              <!-- 一级分类 -->
              <div v-for="(item) in editMemberForm.category_list" :key="'1_'+item.id">
                <!-- {{item.cat_first_name + (item.cat_second_name?`-${item.cat_second_name}`:"")}} -->
                <span>{{item.cat_second_id?"":item.cat_first_name}}</span>
              </div>
              <!-- 二级分类 -->
              <div v-for="(item) in editMemberForm.category_list" :key="'2_'+item.id">
                <span>{{item.cat_second_id?item.cat_second_name:""}}</span>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="常用联系人姓名：" prop="link_name">
            <span>{{editMemberForm.link_name}}</span>
          </el-form-item>
          <el-form-item label="常用联系人手机号：" prop="link_phone">
            <span>{{editMemberForm.link_phone}}</span>
          </el-form-item>
          <el-form-item label="商户类型：" prop="shop_status">
            <span>{{editMemberForm.shop_status==1?"企业":"个人"}}</span>
            <!-- <el-radio v-model="editMemberForm.shop_type" :label="1">企业</el-radio> -->
            <!-- <el-radio v-model="editMemberForm.shop_type" :label="2">个人</el-radio> -->
          </el-form-item>
          <el-form-item label="企业营业执照：" v-if="editMemberForm.shop_status==1">
            <img class="img_yyzz" :src="editMemberForm.licence_img" @click="clickImg(editMemberForm.licence_img)" />
          </el-form-item>
          <template v-else>
            <el-form-item label="身份证正面：">
              <img class="img_yyzz"
                :src="editMemberForm.legal_img&&editMemberForm.legal_img.front"
                @click="clickImg(editMemberForm.legal_img.front)" />
            </el-form-item>
            <el-form-item label="身份证反面：">
              <img class="img_yyzz"
                :src="editMemberForm.legal_img&&editMemberForm.legal_img.back"
                @click="clickImg(editMemberForm.legal_img.back)" />
            </el-form-item>
          </template>
          <template v-if="$route.query.isRefuse">
            <el-form-item label="状态：">
              <span>{{disableMap[editMemberForm.shop_disable]}}</span>
            </el-form-item>
            <el-form-item label="拒绝原因：">
              <span>{{editMemberForm.audit_reason?editMemberForm.audit_reason:'无'}}</span>
            </el-form-item>
          </template>
        </template>
      </el-form>
    </div>
    <!-- 放大图片 -->
    <big-img v-if="showImg" @clickit="viewImg" :imgSrc="imgSrc"></big-img>
  </div>
</template>

<script>
import * as API_Shop from "@/api/shop";
import { RegExp } from "@/libs/common/utils";
import BigImage from '@/components-new/big-Image/big-image'

export default {
  name: "memberEdit",
  components: {
    'big-img': BigImage
  },
  data() {
    return {
      disableMap: {
        'OPEN': '开启中',
        'CLOSED': '店铺关闭',
        'APPLY': '申请开店',
        'REFUSED': '审核拒绝',
        'APPLYING': '申请中',
      },
      shop_id: this.$route.params.id,
      // 编辑会员 表单
      editMemberForm: {},
      // 编辑会员 表单规则
      editMemberRules: {
        shop_name: [
          this.MixinRequired(" 请输入店铺名称！ "),
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20 个字符",
            trigger: "blur"
          },
          {
            validator: (rule, value, callback) => {
              if (!RegExp.userName.test(value)) {
                callback(
                  new Error(" 只支持汉字、字母、数字 、“-” 、 “_”的组合！ ")
                );
              } else {
                callback();
              }
            }
          }
        ],
        link_phone: [
          this.MixinRequired("请输入手机号！"),
          {
            validator: (rule, value, callback) => {
              if (!RegExp.mobile.test(value)) {
                callback(new Error("手机格式有误！"));
              } else {
                callback();
              }
            }
          }
        ],
        link_email: [
          this.MixinRequired("请输入电子邮箱！"),
          {
            validator: (rule, value, callback) => {
              if (value !== 0 && !value) {
                callback();
              } else if (!RegExp.email.test(value)) {
                callback(new Error("邮箱格式有误！"));
              } else {
                callback();
              }
            }
          }
        ],
        // 常用联系人姓名
        link_name: [
          { required: true, message: "请输入常用联系人姓名", trigger: "blur" },
          { min: 1, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ],
        // 常用联系人手机号
        mobile: [
          this.MixinRequired("请输入常用联系人手机号！"),
          {
            validator: (rule, value, callback) => {
              if (!RegExp.mobile.test(value)) {
                callback(new Error("手机格式有误！"));
              } else {
                callback();
              }
            }
          }
        ],
        //  开户行
        bank_belong: [
          { required: true, message: "请输入开户行", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ],
        //  开户行支行
        bank_name: [
          { required: true, message: "请输入开户行支行", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ],
        //  银行账户名
        bank_account_name: [
          { required: true, message: "请输入银行账户名", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ],
        //  账号
        bank_number: [
          { required: true, message: "请输入账号", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ],
        //  财务联系人
        financial_link_name: [
          { required: true, message: "请输入财务联系人", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ],
        //   财务电话
        financial_link_phone: [
          { required: true, message: "请输入电话", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ],
        //  企业名称
        enterprise_name: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ],
        //  社会信用代码
        license_num: [
          { required: true, message: "请输入社会信用代码", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ],
        //  身份证号
        legal_id: [
          { required: true, message: "请输入身份证号", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ],
        //  常用联系人微信号
        link_weixin: [
          {
            required: true,
            message: "请输入常用联系人微信号",
            trigger: "blur"
          },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ],
        //  常用联系人QQ
        link_qq: [
          { required: true, message: "请输入常用联系人QQ", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ],
        // 商户类型
        shop_type: [
          { required: true, message: "请选择商户类型", trigger: "change" }
        ]
      },
      // 地区id数组
      defaultRegion: null,
      // 编辑积分 表单
      editPointForm: {
        currentPoint: 0,
        changedPoint: 0
      },

      // options 分类一级
      optionsOne: [
        {
          id: 1,
          name: "一级分类1"
        },
        {
          id: 2,
          name: "一级分类2"
        }
      ],
      // options 分类二级
      optionsTwo: [
        {
          id: 1,
          name: "二级分类1"
        },
        {
          id: 2,
          name: "二级分类2"
        }
      ],
      showImg: false,
      imgSrc: ''
    };
  },
  mounted() {
    this.GET_ShopDetail();
  },
  // beforeRouteUpdate(to, from, next) {
  //   this.shop_id = to.params.id;
  //   this.GET_ShopDetail();
  //   next();
  // },
  activated() {
    this.shop_id = this.$route.params.id;
  },
  watch: {
    shop_id: function() {
      this.editMemberForm = {};
      this.GET_ShopDetail();
    },
    $route: function() {
      this.editMemberForm = {};
      this.shop_id = this.$route.params.id;
      this.GET_ShopDetail();
    },
  },
  methods: {
    /** 放大图片 */
    clickImg(item) {
      this.showImg = true;
      // 获取当前图片地址
      this.imgSrc = item
    },
    viewImg() {
      this.showImg = false;
    },
    /** 获取商户详情 */
    GET_ShopDetail(flag) {
      let isRefuse = false;
      if (this.$route.query.isRefuse) {
        isRefuse = true
      }
      API_Shop.getShopDetail(this.shop_id, isRefuse).then(response => {
        response.birthday *= 1000;
        response.region = response.town_id || response.county_id;
        response.legal_img = JSON.parse(response.legal_img)
        this.editMemberForm = response;
        this.editPointForm.currentPoint = response.consum_point;
        this.editPointForm.changedPoint = response.consum_point;
        if (response.province_id) {
          this.defaultRegion = [
            response.province_id,
            response.city_id,
            response.county_id || -1,
            response.town_id || -1
          ];
        }
      });
    }
  }
};
</script>

<style type="text/scss" lang="scss" scoped>
.container {
  width: 100%;
  background: #fff;
  border-radius: 12px;
  padding: 0;
  overflow-x: hidden;
  .contant {
    width: 100%;
    margin: 0 36px 36px 100px;
  }
  h1 {
    width: 100%;
    background: #f6f6f6;
    height: 64px;
    line-height: 64px;
    font-weight: 500;
    display: block;
    margin: 0;
    margin-left: -100px;
    padding-left: 30px;
    font-size: 16px;
  }
}

.img_yyzz {
  width: 200px;
  height: 300px;
}
.el-input {
  width: 280px !important;
}
.el-date-editor.el-input {
  width: 180px;
}
::v-deep .form-item-sex .el-form-item__content {
  width: 180px;
}
::v-deep .form-item-region .el-form-item__content {
  min-width: 180px;
}
::v-deep .el-form-item__error {
  min-width: 240px;
}
::v-deep .el-tabs__content {
  overflow: initial;
}
::v-deep .el-tab-pane .el-button.save {
  margin-left: 100px;
}
.myNumber {
  width: 160px;
}
</style>
